.wrapper {
  margin-top: 100px;
  text-align: center;
}

.actions {
  display: flex;
  width: 540px;
  margin: 0 auto;
  justify-content: space-between;
}

.actionButton {
  width: 250px;
  margin: 0 10px;
}
