@import 'joy-ui/styles/variables.scss';

.wrapper {
  width: calc(100vw - 200px);
  height: calc(100vh - 80px);
  position: relative;
}

.wrapperMobile {
  @extend .wrapper;
  width: calc(100vw - 5px);
  height: calc(100vh - 5px);
}

.closeButton {
  position: fixed;
  z-index: 50;
  top: 30px;
  right: 30px;
}

.closeButtonMobile {
  @extend .closeButton;
  top: 5px;
  right: 5px;
}

.actions {
  position: absolute;
  z-index: 50;
  top: 49%;
}

.prevButton {
  @extend .actions;
  left: 50px;
}

.nextButton {
  @extend .actions;
  right: 50px;
}

.mediaItem {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.imageItem {
  @extend .mediaItem;
}

.videoItem {
  @extend .mediaItem;
}
