@import 'joy-ui/styles/variables.scss';

.wrapper {
  flex: 1 1;
  max-height: 95vh;
  padding-left: 15px;
  margin-bottom: 50px;
  min-width: 500px;
  overflow: hidden;
}

.title {
  margin: 30px 10px 20px;
  letter-spacing: 1.5px;
}

.listContainer {
  width: 100%;
  height: 99%;
  overflow: auto;
  padding-left: 10px;
  padding-bottom: 53px;

  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */

  // /* Hide scrollbar for Chrome, Safari and Opera */
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.loadingLoop {
  margin-top: 20%;
}

.deleteAction {
  float: right;
}

.dialogTitle {
  text-align: center;
}

.dialogSubtitle {
  text-align: justify;
  margin-top: 30px;
}
