@import 'joy-ui/styles/variables.scss';

.wrapper {
  display: flex;
  height: 50px;
  margin-top: 40px;
  border-bottom: 1px solid $joyMutedLight;
  align-content: center;
  justify-content: space-between;
}

.title {
  display: flex;
  flex-direction: row;
}

.groupTitle {
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline 2px !important;
  }
}

.albumTitle {
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.peopleList {
  display: flex;
  flex-direction: row;
}

.personAvatar {
  margin-left: 5px;
}
