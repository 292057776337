@import 'joy-ui/styles/variables.scss';

.row {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
}

.rowMobile {
  margin-bottom: 8px;
}

.itemSelect {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 50ms linear;
}

.showSelected {
  visibility: visible;
  opacity: 1;
}

.mediaGridMessage {
  text-align: center;
  margin-top: 30%;
}

.videoMark {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 1;
}

.optionsMenu {
  position: relative;
  top: 10px;
  right: 34px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 50ms linear;
}

.mediaItem {
  position: relative;
  transition: all 100ms ease-in-out;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.idleItem {
  &:hover {
    transform: scale(1.003);
    box-shadow: $joyBoxShadowMedia;
    background-color: $joySecondary !important;

    button,
    svg {
      visibility: visible;
      opacity: 1;
    }

    img {
      opacity: 0.7;
    }
  }
}

.deleteConfirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $joySecondary;
}

.loopOverlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #00000040;
}

.yesOption,
.noOption {
  min-width: 50px;
  width: 100px;
  height: 40px;
  padding: 0;
  line-height: 40px;
  color: $white;
}

.mediaItemMobile {
  &:not(:last-child) {
    margin-right: 8px;
  }
}

.mediaItemSelected {
  &::after {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $joySecondary;
    opacity: 0.7;
    top: 0;
    left: 0;
    display: block;
    content: '';
  }
}

.thumbnail {
  width: 100%;
  height: auto;
  float: left;
}
