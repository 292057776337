@import 'joy-ui/styles/variables.scss';

.navHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 30px;
  border-bottom: 1px solid $joyMutedLight;
}

.closeMenu {
  margin-top: 20px;
}

.userPanel {
  margin-left: 25px;
}

.userName {
  margin-top: 20px;
  width: 229px;
  height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userEmail {
  margin-top: 4px;
  margin-bottom: 20px;
  width: 229px;
  height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mainContent {
  position: absolute;
  width: 350px;
  top: 248px;
  bottom: 84px;
  padding: 30px;
  overflow: auto;
}

.contentTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addAction {
  margin-top: 20px;
}

.groupItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

.groupDetails {
  margin-left: 20px;
}

.groupName {
  width: 175px;
  height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.groupOwner {
  width: 175px;
  height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatarsGrid2 {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
}

.avatars2 {
  width: 20px;
}

.avatarsGrid4 {
  width: 40px;
  height: 40px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: wrap;
  border-radius: 50%;
  overflow: hidden;
}

.selectedGroup {
  background-color: $joySecondary;
  border-radius: 6px;
}

.navFooter {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 30px 80px;
  border-top: 1px solid $joyMutedLight;
}

.signOutAction {
  width: 70px;
  cursor: pointer;
}
