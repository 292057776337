@import 'joy-ui/styles/variables.scss';

.wrapper {
  width: 100%;
  height: 82px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.message {
  width: 100%;
}

.inputField {
  width: 200px;
  height: 40px;
  padding: 5px 10px;
  border-radius: 5px;

  border: 1px solid $joyDisabled;
  font-weight: 600;

  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: $joyDisabled;
  }

  &:focus {
    border-color: $joySecondary;
    color: $joyBlack;
  }
}

.inputError {
  color: $joyError;
  border-color: $joyError;

  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: $joyError !important;
  }

  &:focus {
    border-color: $joyError;
    color: $joyError;
  }
}

.placeholder {
  flex: 1;
}
