.wrapper {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #dcdcdc; //$joyBorderColor

  & > input {
    font-size: 16px;
    width: 100% !important;
  }
}

.label {
  color: #949494; //$joyMuted;
  position: absolute;
  font-size: 12px;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  text-transform: capitalize;
  transition: all 0.2s ease-out;
}
