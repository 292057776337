@import 'joy-ui/styles/variables.scss';

.wrapper {
  width: 335px;
  max-height: 95vh;
  padding-right: 25px;
  overflow: hidden;
}

.title {
  margin: 30px 0 20px;
  letter-spacing: 1.5px;
}

.listContainer {
  width: 100%;
  height: 99%;
  overflow: auto;
  padding-right: 15px;
  padding-bottom: 24px;

  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */

  // /* Hide scrollbar for Chrome, Safari and Opera */
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.message {
  margin-top: 60px;
  text-align: center;
}

.albumItem {
  position: relative;
  width: 310px;
  height: 200px;
  margin-bottom: 30px;
  border-radius: 3px;
  background-color: $joyMutedLight;
  background-size: cover;
  overflow: hidden;
  cursor: pointer;
}

.groupThumbnail {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  height: 100%;
}

.grid1 {
  width: 100%;
  height: 100%;
  display: inline-block;
  background-size: cover;
  background-position: center;
}

.grid2 {
  width: 50%;
  height: 100%;
  display: inline-block;
  background-size: cover;
  background-position: center;
}

.grid4 {
  width: 50%;
  height: 50%;
  display: inline-block;
  background-size: cover;
  background-position: center;
}

.optionsMenu {
  position: relative;
  top: 10px;
  left: 255px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 50ms linear;
}

.dialogTitle {
  text-align: center;
}

.dialogSubtitle {
  text-align: justify;
  margin-top: 30px;
}

.loadingLoop {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -7px;
  margin-left: -25px;
}

.titleOverlay {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#00000000 66%, #00000078);
}

.albumTitle {
  position: absolute;
  left: 20px;
  bottom: 20px;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectedAlbum {
  border-radius: 5px;
  border: 10px solid #a2deec;

  &:hover {
    button,
    svg {
      visibility: visible;
      opacity: 1;
    }
  }
}

.blurredAlbum {
  background-color: #ffffff60;
  background-image: none;
}
