@import 'joy-ui/styles/variables.scss';

.wrapper {
  position: relative;

  &::after {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    content: '';
    z-index: 9;
    opacity: 0;
    border-radius: 3px;
    background-color: $joySecondary;
  }
}

.hoverEffect {
  * {
    pointer-events: none;
  }

  &::after {
    display: block;
    opacity: 0.3;
  }
}
