.wrapper {
  margin-top: 100px;
  text-align: center;
}

.subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
}
