@import 'joy-ui/styles/variables.scss';

.content {
  display: flex;
  flex-direction: column;
  padding: 0 100px;
  min-width: 500px;
  width: 100%;
}

.title {
  margin-top: 8px;
  margin-bottom: 30px;
}

.card {
  display: flex;
  flex-direction: row;
  height: 180px;
  min-width: 600px;
  width: 100%;
  padding: 50px;
  box-shadow: $joyBoxShadow;
}

.details {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.placeholder {
  flex: 1;
}

.deleteConfirmation {
  min-width: 600px;
}
