.avatar {
  width: 105px;
  height: 130px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.editText {
  cursor: pointer;
}
