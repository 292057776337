@import 'joy-ui/styles/variables.scss';

.wrapper {
  position: relative;
}

.calendar {
  position: absolute;
  z-index: 10;
}

.closeIcon {
  position: absolute;
  right: -25px;
  box-sizing: content-box;
  border: 1px solid #a0a096;
  border-left: 0px;
}

.label {
  position: relative;
  margin-top: 32px;
  padding-top: 3px;
  padding-bottom: 6px;
  border-bottom: 1px solid #dcdcdc;
}

.labelValue {
  padding-top: 8px;
  padding-bottom: 10px;
  cursor: pointer;
  user-select: none;
}

.labelEmpty {
  line-height: 41px;
}

.clearIcon {
  position: absolute;
  top: 14px;
  right: 0;
}
