@import 'joy-ui/styles/variables.scss';

.loadingLoop {
  margin-top: 20%;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.headerLeftContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerWrapper {
  background-color: $joySecondary;
}
