@import 'joy-ui/styles/variables.scss';

.uploadArea {
  width: 360px;
  height: 360px;
  margin-top: 40px;
  border-radius: 3px;
  border: 2px dashed $joySecondary;
  background-color: $joyOffWhite;
  text-align: center;
}

.uploadIcon {
  margin-top: 50px;
}
