@import 'joy-ui/styles/variables.scss';

.listAction {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid $joyBorderColor;
  cursor: pointer;
}

.actionIcon {
  width: 75px;
  height: 65px;
  margin-right: 20px;
  line-height: 90px;
  text-align: center;
  border-radius: 3px;
  background-color: $joyPrimary;
  background-size: cover;
}

.albumsList {
  max-height: 40vh;
  overflow-y: auto;
}

.actionThumbnail {
  width: 75px;
  height: 65px;
  border-radius: 3px;
}
