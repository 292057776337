@import 'joy-ui/styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 1440px;
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
}

.card {
  width: 600px;
  margin: auto;
  position: relative;
  background-color: $white;
  box-shadow: $joyBoxShadow;
  padding: 70px;
  border-radius: 3px;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
}

.cardActions {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  > button {
    width: 100%;
    margin: 0 10px;
  }
}
