@import 'joy-ui/styles/variables.scss';

.container {
  display: flex;
  flex-direction: row;
  //   min-width: 1150px;
  background-color: $white;
  padding: 60px 0;
  overflow: hidden;
}

.navigation {
  display: flex;
  flex-direction: column;
  padding: 0 100px;
  min-height: 630px;
  border-right: 1px solid $joyBorderColor;
}

.itemLink {
  width: 250px;
  height: 75px;
  text-align: left;
  border-bottom: 1px solid $joyBorderColor;

  &:last-child {
    border-bottom-color: $white;
  }
}

.itemLinkText {
  padding-left: 20px;
  line-height: 75px;
}

.itemLinkSelected {
  background-color: $joySecondary !important;
  border-radius: 3px;
  color: $white !important;
}

.sideContent {
  padding: 0 100px;
  min-width: 500px;
}
