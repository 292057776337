@import 'joy-ui/styles/variables.scss';

.pageExtension {
  margin-top: 40px;
  text-align: center;
}

.oauthActions {
  display: flex;
  margin-top: 30px;
  justify-content: space-around;
}

.oauthButton {
  cursor: pointer;
}
