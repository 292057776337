@import 'joy-ui/styles/variables.scss';

.wrapper {
  min-width: 460px;
  padding: 50px;
}

.settingsIcon {
  margin-top: 1px;
  margin-left: 12px;
}

.groupNameDisplay {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -30px;
  padding-top: 7px;
  padding-bottom: 8px;
}

.groupNameLabel {
  margin-right: 10px;
  flex-grow: 1;
  max-width: 314px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.groupNameEditor {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -30px;
}

.groupNameInput {
  margin-top: 0;
  margin-right: 10px;
  flex-grow: 1;
}

.groupDeleteButton {
  margin-left: -30px;
}

.listTitle {
  letter-spacing: 1.5px;
}

.dialogTitle {
  text-align: center;
}

.dialogSubtitle {
  text-align: justify;
  margin-top: 30px;
}

.loadingLoop {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #ffffffc9;
}

.listAction {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid $joyBorderColor;
}

.peopleList {
  max-height: 40vh;
  overflow-y: auto;
}

.userAvatar {
  margin-right: 15px;
}

.userName {
  flex-grow: 1;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actionIcon {
  width: 40px;
  height: 40px;
  padding-top: 5px;
  padding-left: 5px;
  margin-right: 15px;
  background-color: $joyMutedLight;
  border-radius: 20px;
  cursor: pointer;
}
