@import 'joy-ui/styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 45%;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  background-color: $joyPrimary;
  border-radius: 15px;
}

.heading {
  margin-top: 30px;
}

.content {
  margin: 15px;
  max-width: 320px;
}

.storeActions {
  margin-top: 50px;
}
